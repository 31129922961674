// MODULES //////////
import React from 'react';
import ReactDOM from 'react-dom';
import { Transition, TransitionGroup, CSSTransition } from 'react-transition-group';
// LIBS //////////
import TweenMax from 'gsap/TweenMax';
// ITEMS JAVASCRIPT //////////
import { SET_WORKS_FULLSCREEN, WORKS_OFF, WORKS_ON, WORKSFULL_RESET } from './../../js/parts/effects/itemsClick';
import { ImagesDom } from './../../js/parts/loadImages';

/**
 * @namespace React > Component > Layout_Works
 */
export class Layout_Works extends React.Component {

  constructor(props) {
    super(props);
    this.state = ({
      currentCategory: this.props.defCategory,
      layoutNarrow : this.props.layoutNarrow
    });
    // bind
    this.switchCategory = this.switchCategory.bind(this);
    this.off=this.off.bind(this);
    this.IMG_WIDTH=2000;
    this.IMG_HEIGHT=1280;

    this.curIndex=0;
    this.curLayout="wide";
    this.cursor;

    this.scrollVal;
    this.scrollReq;
    this.t_line=new TimelineMax();
    this.isTrans=false;
  }

  componentWillMount() {
    SHIMA_PORTFOLIO.Works.activeCategoryIdx=0;
    if (SHIMA_PORTFOLIO.Works.imagesDom.length==0) {
      ImagesDom.init();
    }
  }

  componentDidMount() {
    document.title="works - Takatsugu Shimazaki Photography | 写真家　島崎隆次";
    this.fetchData();
    setTimeout(function() {
      SHIMA_PORTFOLIO.stack.isFirst=false;
      SHIMA_PORTFOLIO.stack.body.classList.remove("_evtOff");
    },SHIMA_PORTFOLIO.stack.isFirst==true ? 5400 : 2400);
    this.setMouseEvents();
  }

  fetchData() {
    let self = this;
    setTimeout(function() {
      self.initPosition();
      SHIMA_PORTFOLIO.stack.loaded=true;
    }, 1000);
  }

  initPosition() {
    let self = this;
    setTimeout(
      function() {
        SHIMA_PORTFOLIO.Works.first=false;
        let Works=document.getElementById("Works");
        Works.classList.remove("__fixed");
        SET_WORKS_FULLSCREEN();
      }, (SHIMA_PORTFOLIO.Works.first==true) ? 4200 : 1200
    );
    setTimeout(
      function() {
        if (document.querySelector("#all") !==null) {
          document.getElementById("all").classList.add('_on');
        }
      }, (SHIMA_PORTFOLIO.Works.first==true) ? 5800 : 2200
    );
  }

  scrollPosition() {
    return Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);
  }

  switchCategory(e) {
    let self=this;
    SHIMA_PORTFOLIO.Works.activeCategoryIdx=e.currentTarget.getAttribute('data-category-index');
    if (this.isTrans==true || this.curIndex==SHIMA_PORTFOLIO.Works.activeCategoryIdx) return;
    this.isTrans=true;
    this.curIndex=SHIMA_PORTFOLIO.Works.activeCategoryIdx;
    let clickedCate=e.currentTarget.getAttribute('data-category-name');
    if (clickedCate !== "All") document.getElementById("all").classList.remove('_on');
    this.t_line.to(
      document.querySelector('#works__work ul'),
      .6,
      {
        delay:0, opacity:0,
        onComplete: function() {
          self.scrollToTop();
          self.setState({ currentCategory: clickedCate });
        }
      }
    )
    .to(
      document.querySelector('#works__work ul'),
      1,{delay:.2, opacity:1,
      onComplete: function() {
        self.isTrans=false;
        self.setMouseEvents();
      }}
    );
  }

  switchLayout(layout) {
    let self=this;
    if (this.isTrans==true || this.curLayout==layout) return;
    this.isTrans=true;
    this.curLayout=layout;
    this.t_line.to(
      document.querySelector('#works__work ul'),
      .6,
      {
        delay:0, opacity:0,
        onComplete: function() {
          self.scrollToTop();
          if (layout == 'narrow') self.setState({ layoutNarrow: true });
          else { self.setState({ layoutNarrow: false }); }
        }
      }
    )
    .to(
      document.querySelector('#works__work ul'),
      1,{delay:.2, opacity:1, onComplete: function() {self.isTrans=false;}}
    );
  }

  scrollToTop() {
    window.scrollTo(0, 0);
  }

  off() {
    if (SHIMA_PORTFOLIO.Works.isWorksClicked) {
      SHIMA_PORTFOLIO.Works.isWorksClicked=false;
      WORKS_OFF();
    }
  }

  setMouseEvents() {
    let x=0;
    let y=0;
    let t,id,name;
    let cursor=document.getElementById("works__cursor");
    let fullImage=document.getElementById("works__full");
    let images=document.querySelectorAll("#works__work div ul li");
    for (let i=0,li=images.length;i<li;i++) {
      // mouse over
      images[i].addEventListener(
        "mouseover",
        function(e) {
          cursor.classList.add('_on');
        }
      );
      // mouse move
      images[i].addEventListener(
        "mousemove",
        function(e) {
          x=e.clientX;
          y=e.clientY;
          cursor.classList.add('_on');
          TweenMax.to(cursor,.5,{autoAlpha:1,x:x+36,y:y+37});
        }
      );
      // mouse out
      images[i].addEventListener(
        "mouseout",
        function(e) {
          if (cursor.classList.contains('_open')==true) return;
          cursor.classList.remove('_on');
        }
      );
      // mouse click
      images[i].addEventListener(
        "click",
        function(e) {
          id=e.currentTarget.getAttribute('data-id');
          name=e.currentTarget.getAttribute('data-project');
          cursor.classList.add('_open');
          WORKS_ON(id,name);
        }
      );
    }
    // full image
    fullImage.addEventListener(
      "mousemove",
      function(e) {
        x=e.clientX;
        y=e.clientY;
        TweenMax.to(cursor,.5,{autoAlpha:1,x:x+35,y:y+38});
      }
    );
  }

  render() {
    const {
      currentCategory,
      layoutNarrow
    } = this.state;
    let self = this;
    let categoryLst=[];
    SHIMA_PORTFOLIO.Works.category.map(
      function(data, index) {
        categoryLst.push(
          <li
            key={ index }
            data-category-index={ index+1 }
            data-category-name={ SHIMA_PORTFOLIO.Works.category[index] }
            className={ (currentCategory == SHIMA_PORTFOLIO.Works.category[index] ?  '_on' : '') }
            onClick={ self.switchCategory }>
            <div>
              <a href="javascript:void(0)">
                <span>
                  <span className="t">{ data }</span>
                  <span className="t_hor">{ data }</span>
                </span>
              </a>
              <span className="sheets">{ SHIMA_PORTFOLIO.Works.categoryNumber[index] }</span>
            </div>
          </li>
        );
      }
    );
    return (
      <div id={ this.props.cur } className="__fixed">
        <div className={ this.props.cur }>
          <div id="works__cat">
            <ul>
              <li
                data-category-index={ 0 }
                data-category-name={ 'All' }
                id="all"
                className={ (currentCategory == 'All' ? '_on' : '') }
                onClick={ self.switchCategory }>
                <a href="javascript:void(0)">
                  <span>
                    <span className="t">All</span>
                    <span className="t_hor">All</span>
                  </span>
                </a>
                <span className="sheets">{ SHIMA_PORTFOLIO.Works.allNumber }</span>
              </li>
              { categoryLst }
            </ul>
          </div>
          <div id="works__work" className={ (layoutNarrow == true ? '_narrow' : '_wide') }>
            <div>
              <ul>{ SHIMA_PORTFOLIO.Works.imagesDom[SHIMA_PORTFOLIO.Works.activeCategoryIdx] }</ul>
            </div>
          </div>
          <div id="works__switch" className={ (layoutNarrow == true ? '_narrow' : '') }>
            <span id="works__switch__l" onClick={ this.switchLayout.bind(this, 'wide') }>
              <a href="javascript:void(0)">
                <span>
                  <span></span><span></span><span></span>
                </span>
              </a>
            </span>
            <span id="works__switch__s" onClick={ this.switchLayout.bind(this, 'narrow') }>
              <a href="javascript:void(0)">
                <span></span><span></span><span></span>
                <span></span><span></span><span></span>
              </a>
            </span>
          </div>
          <div id="works__full"
            onClick={ this.off }>
            <a href="javascript:void(0)" id="works__project" target="blank">Project Name</a>
            <div id="works__trigger">
              <button type="button">
                <span className="lines">
                  <span><span></span></span>
                  <span><span></span></span>
                </span>
              </button>
            </div>
          </div>
          <div id="works__cursor">
            <p className="detail">Detail</p>
            <p className="close">Close</p>
            <span className="circ"></span>
            <span className="cross"></span>
          </div>
        </div>
      </div>
    )
  }
}


Layout_Works.defaultProps = {
  defCategory: "",
  layoutNarrow: false
};

