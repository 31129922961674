import glslify from 'glslify';
/* 
Vertex 
*/
export const vert = glslify(`
  attribute vec3 position;
  attribute vec2 uv;
  varying vec2 vUv;
  void main() {
    vUv = uv;
    gl_Position = vec4(position, 1.0);
    // vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
    // gl_Position = projectionMatrix * mvPosition;
  }
`);
/*
Fragment
*/
export const frag = glslify(`
  precision mediump float;
  varying vec2 vUv;
  uniform sampler2D uWorks;
  uniform sampler2D uTex;
  uniform float uTrans;
  uniform vec2 uResolution;
  uniform vec2 uImgResolution;
  uniform float uTime;
  // uniform float uMix;
  uniform bool uIsRetina;
  //
  vec4 color;
  vec4 dest;
  float gap = 0.50;
  float effectFactor = 0.30;
  float PixelRatio = 0.5;
  vec4 contrast(vec4 colors, float contrast) {
    colors.rgb = colors.rgb / colors.a;
    colors.rgb = ((colors.rgb - 0.5) * max(contrast + 1.0, 0.0)) + 0.5;
    colors.rgb *= colors.a;
    return colors;
  }
  vec4 brightness(vec4 colors, float brightness) {
    colors.rgb = colors.rgb / colors.a;
    colors.rgb += brightness;
    colors.rgb *= colors.a;
    return colors;
  }
  void main(void) {
    vec2 AspectRatio = vec2(
      min((uResolution.x / uResolution.y) / (uImgResolution.x / uImgResolution.y), 1.0),
      min((uResolution.y / uResolution.x) / (uImgResolution.y / uImgResolution.x), 1.0)
    );
    vec2 uv = vec2(
      vUv.x * AspectRatio.x + PixelRatio * (1.0 - AspectRatio.x),
      vUv.y * AspectRatio.y + PixelRatio * (1.0 - AspectRatio.y)
    );
    // vec2 uv = (gl_FragCoord.xy * 2.0 - uResolution.xy) / min(uResolution.x, uResolution.y);
    vec4 tex = texture2D(uTex, uv);
    float trans = gap*tex.r*effectFactor*uTrans;
    // float trans2 = gap*tex.r*effectFactor*uTransFactor_2;
    // float slideDist1 = uTransFactor_1*0.045;
    // float slideDist2 = uTransFactor_2*0.045;
    // float flootX = 0.016*cos(flootVel*uTime);
    // float flootY = 0.018*sin(flootVel*uTime*0.70);

    float rBrigthness = 1.4*sin(uTrans+uTrans*1.2); // no effect 0.0
    float rContrast = 0.4*sin(uTrans); // no effect 0.0

    color = texture2D(uWorks, vec2(uv.x, uv.y));
    // dest = mix(color1, uIsStay==true ? vec4(KEYCOLOR, 1.0) : color2, uMix);  // color1(1 - uTransFactor_1) + color2 * uTransFactor_1
    dest = brightness(contrast(color, rContrast), rBrigthness);



    gl_FragColor = dest;
  }
`);



