// MODULES //////////
import React from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter,
  Router,
  Switch,
  Route,
  browserHistory,
  withRouter,
  Link
} from "react-router-dom";
import * as THREE from "three";
import TweenMax from "gsap/TweenMax";
// STYLES //////////
import "./../css/app.scss";
// ROUTING JSX //////////
import App from "./../jsx/App.jsx";
// JAVASCRIPT //////////
import { MAIN_RESIZE } from "./parts/effects/main";
import { ITEMS_CLICK_RESIZE } from "./parts/effects/itemsClick";

window.SHIMA_PORTFOLIO = {
  /**
   * @namespace SHIMA_PORTFOLIO > stack
   */
  stack: {
    body: document.body,
    rowRoot: document.getElementById("app_root"),
    bundledScript: document.getElementById("bundledScript"),
    isSmt: null,
    backVisualEffect: true,
    location: null,
    activeLocation: null,
    locationExclusiveProcess: null,
    loaded: false,
    isAccess: true,
    isFirst: true,
    directory: "",
    isLoadingAnimation: true,
    isRenderStart: false,
    kvCurrent: 1, // kv current
    isPageTransition: false, // ページ遷移時 true
    pageTransitionClickHandler: true,
    BREAKPOINT: 749
  },
  classNames: {
    appear: "Set",
    appearActive: "SetActive",
    enter: "SetEnter",
    enterActive: "SetActiveEnter",
    enterDone: "SetDoneEnter",
    exit: "SetExit",
    exitActive: "SetActiveExit",
    exitDone: "SetDoneExit"
  },
  /**
   * @namespace SHIMA_PORTFOLIO > three
   */
  three: {
    RENDERER_MAIN: new THREE.WebGLRenderer({ antialias: true, alpha: true }),
    RENDERER_WORKS_CLICK: new THREE.WebGLRenderer({
      antialias: true,
      alpha: true
    })
  },
  /**
   * @namespace SHIMA_PORTFOLIO > Home
   */
  Home: {
    globalImages: [
      // TOP
      "/image/home/01.jpg",
      "/image/home/02.jpg",
      "/image/home/03.jpg",
      // '/image/home/04.jpg',
      // ABOUT
      "/image/about/01.jpg",
      // CONTACT
      "/image/contact/01.jpg",
      // DCDCDC
      "/image/common/dcdcdc.jpg",
      // TEXTURE
      "/image/common/texture/tex.jpg"
    ],
    globalSlide: [],
    bgTexLoad: new THREE.TextureLoader()
  },
  /**
   * @namespace SHIMA_PORTFOLIO > About
   */
  /**
   * @namespace SHIMA_PORTFOLIO > Works
   */
  Works: {
    first: true,
    works_json: null,
    eachCategory: [],
    imagesDom: [], // each cates li
    category: [],
    categoryNumber: [],
    activeCategoryIdx: 0,
    allNumber: 0,
    worksActiveImage: 0,
    isWorksClicked: true,
    isWorksLayoutType: "wide",
    isTransition: false,
    switchLayoutFlg: false,
    inviewTex: new THREE.TextureLoader(),
    mouse: new THREE.Vector2(),
    EACH_LOADED_TEXTURES: [],
    OVER_TEXTURE: "/image/common/texture/02.jpg"
  },
  /**
   * @namespace SHIMA_PORTFOLIO > Contact
   */
  /**
   * @namespace SHIMA_PORTFOLIO > func
   */
  func: {
    init: function() {
      SHIMA_PORTFOLIO.func.device();
      SHIMA_PORTFOLIO.func.fetch();
    },
    setRouting: function() {
      ReactDOM.render(
        <BrowserRouter>
          <App />
        </BrowserRouter>,
        SHIMA_PORTFOLIO.stack.rowRoot
      );
    },
    fetch: function() {
      // top
      for (
        let s = 0, bLi = SHIMA_PORTFOLIO.Home.globalImages.length;
        s < bLi;
        s++
      ) {
        let te = SHIMA_PORTFOLIO.Home.bgTexLoad.load(
          SHIMA_PORTFOLIO.Home.globalImages[s]
        );
        te.name = s;
        te.magFilter = THREE.LinearFilter;
        te.minFilter = THREE.LinearFilter;
        te.anisotropy = SHIMA_PORTFOLIO.three.RENDERER_MAIN.capabilities.getMaxAnisotropy();
        SHIMA_PORTFOLIO.Home.globalSlide.push(te);
      }
      // works
      let worksJsonPath = "/data/works/data.json";
      let worksSet = [];
      let allCategory = [];
      let eachWorks = [];
      let allWorks = [];
      let t = null;
      let req = new XMLHttpRequest();
      req.onreadystatechange = function() {
        if (req.readyState == 4 && req.status == 200) {
          let data = JSON.parse(req.responseText);
          SHIMA_PORTFOLIO.Works.works_json = data;
          for (let h = 0, li_c = Object.keys(data).length; h < li_c; h++) {
            SHIMA_PORTFOLIO.Works.category.push(Object.keys(data)[h]);
            SHIMA_PORTFOLIO.Works.categoryNumber.push(
              data[Object.keys(data)[h]].length
            );
            SHIMA_PORTFOLIO.Works.allNumber += Number(
              data[Object.keys(data)[h]].length
            );
          }
          for (
            let i = 0, li = SHIMA_PORTFOLIO.Works.category.length;
            i < li;
            i++
          ) {
            eachWorks = [];
            if (
              SHIMA_PORTFOLIO.Works.works_json[
                SHIMA_PORTFOLIO.Works.category[i]
              ].length > 0
            ) {
              for (
                let j = 0,
                  li_ =
                    SHIMA_PORTFOLIO.Works.works_json[
                      SHIMA_PORTFOLIO.Works.category[i]
                    ].length;
                j < li_;
                j++
              ) {
                allCategory.push(
                  SHIMA_PORTFOLIO.Works.works_json[
                    SHIMA_PORTFOLIO.Works.category[i]
                  ][j]
                );
                t = SHIMA_PORTFOLIO.Works.inviewTex.load(
                  SHIMA_PORTFOLIO.Works.works_json[
                    SHIMA_PORTFOLIO.Works.category[i]
                  ][j]["work"]
                );
                t.magFilter = THREE.LinearFilter;
                t.minFilter = THREE.LinearFilter;
                t.needsUpdate = true;
                t.crossOrigin = "*";
                eachWorks.push(t);
                allWorks.push(t);
              }
            }
            // each (allを含む 6項目)
            SHIMA_PORTFOLIO.Works.EACH_LOADED_TEXTURES.push(eachWorks);
            SHIMA_PORTFOLIO.Works.eachCategory.push(
              SHIMA_PORTFOLIO.Works.works_json[
                SHIMA_PORTFOLIO.Works.category[i]
              ]
            );
          }
          SHIMA_PORTFOLIO.Works.EACH_LOADED_TEXTURES.unshift(allWorks);
          SHIMA_PORTFOLIO.Works.eachCategory.unshift(allCategory);
          SHIMA_PORTFOLIO.func.loading();
        }
      };
      // req.open("GET", worksJsonPath, false);
      req.open("GET", worksJsonPath, true);
      req.send(null);
    },
    events: function() {
      let timer;
      let dec = 1000;
      let currentWidth;
      window.addEventListener("resize", function(event) {
        if (timer !== false) clearTimeout(timer);
        timer = setTimeout(function() {
          MAIN_RESIZE();
          SHIMA_PORTFOLIO.func.device();
          if (SHIMA_PORTFOLIO.stack.location == "/") {
          } else if (SHIMA_PORTFOLIO.stack.location == "/works/") {
            if (
              SHIMA_PORTFOLIO.stack.isSmt == true &&
              currentWidth == SHIMA_PORTFOLIO.func.clientSize().W
            )
              return;
            ITEMS_CLICK_RESIZE();
          }
          currentWidth = SHIMA_PORTFOLIO.func.clientSize().W;
        }, dec);
      });
    },
    clientSize: function() {
      let w = window.innerWidth;
      let h = window.innerHeight;
      return {
        W: w,
        H: h
      };
    },
    device: function() {
      if (
        SHIMA_PORTFOLIO.stack.body.clientWidth <
        SHIMA_PORTFOLIO.stack.BREAKPOINT
      ) {
        SHIMA_PORTFOLIO.stack.body.removeAttribute("id");
        SHIMA_PORTFOLIO.stack.body.setAttribute("id", "SMT");
        SHIMA_PORTFOLIO.stack.isSmt = true;
      } else if (
        SHIMA_PORTFOLIO.stack.body.clientWidth >=
        SHIMA_PORTFOLIO.stack.BREAKPOINT
      ) {
        SHIMA_PORTFOLIO.stack.body.removeAttribute("id");
        SHIMA_PORTFOLIO.stack.body.setAttribute("id", "PC");
        SHIMA_PORTFOLIO.stack.isSmt = false;
      }
    },
    loading: function() {
      const Name = "TAKATSUGUSHIMAZAKI";
      let span_arr = [];
      let div = document.createElement("div");
      div.setAttribute("id", "loading");
      SHIMA_PORTFOLIO.stack.bundledScript.parentNode.insertBefore(
        div,
        SHIMA_PORTFOLIO.stack.bundledScript
      );
      let p = document.createElement("p");
      p.className = "loadTxt";
      div.appendChild(p);
      for (let i = 0, li = Name.length; i < li; i++) {
        let span = document.createElement("span");
        let r = SHIMA_PORTFOLIO.func.random(7, 0);
        span.textContent = Name[i];
        span.className = "l";
        p.appendChild(span);
        span_arr.push(Name[i]);
      }
      let targets = [];
      let buffer = 1900;
      const content = document.getElementsByClassName("content");
      let loadin = document.getElementById("loading");
      TweenMax.staggerTo(
        ".l",
        1.4,
        { ease: "linear", opacity: 1 },
        0.1,
        loadTimerGo
      );
      // 表示
      function loadTimerGo() {
        SHIMA_PORTFOLIO.func.events();
        SHIMA_PORTFOLIO.func.setRouting();
        let loadTimer = setInterval(function() {
          // console.log("ロード中");
          if (SHIMA_PORTFOLIO.stack.loaded) {
            clearInterval(loadTimer);
            targets = [];
            targets.forEach.call(content, function(elm) {
              elm.classList.add("_isload");
              setTimeout(function() {
                SHIMA_PORTFOLIO.stack.isLoadingAnimation = false;
              }, buffer);
            });
            // console.log("ロード完了");
            let Ready = setInterval(function() {
              if (!SHIMA_PORTFOLIO.stack.isLoadingAnimation) {
                clearInterval(Ready);
                // console.log("ロードアニメ終了");
                targets = [];
                targets.forEach.call(content, function(elm) {
                  setTimeout(function() {
                    elm.classList.remove("_isload");
                    elm.classList.add("_on");
                  }, 400);
                });
                TweenMax.staggerTo(
                  ".l",
                  1.2,
                  { backfaceVisibility: "hidden", opacity: 0 },
                  0.08,
                  removeLoadEl
                );
                function removeLoadEl() {
                  div.parentNode.removeChild(div);
                }
                SHIMA_PORTFOLIO.stack.isRenderStart = true;
              }
            }, 1000);
          }
        }, 1000);
      }
    },
    random: function(max, min) {
      let sign = "";
      if (Math.floor(Math.random() * (2 - 0) + 0) == 0) sign = "-";
      return sign + Math.random() * (max - min) + min;
    }
  }
};

window.addEventListener("load", function() {
  SHIMA_PORTFOLIO.func.init();
});
