// MODULES //////////
import glslify from "glslify";
import * as THREE from "three";
import { vert, frag } from "./shader/bg/script";
// LIBS //////////
import TweenMax from "gsap/TweenMax";
// EASE JAVASCRIPT ////////
import { Ease } from "./../easing";

/**
 * @namespace GlobalBg
 */
let GlobalBg = {};

GlobalBg.El;
GlobalBg.uniforms;
GlobalBg.Three = {
  renderer: null,
  material: null,
  geometry: new THREE.PlaneBufferGeometry(2, 2),
  mesh: null,
  camera: null,
  scene: new THREE.Scene()
};

GlobalBg.S = {
  imgWidth: 3500,
  imgHeight: 2336
};

GlobalBg.Tex = {
  top1: null,
  top2: null,
  top3: null,
  top4: null,
  about: null,
  contact: null,
  dc: null,
  keyImages: []
};

GlobalBg.Timer = {
  gb: null,
  transition: null
};

GlobalBg.P = {
  c: 0,
  val: 0,
  prevSlide: 0,
  nextSlide: 1,
  valSet: [1.0, 0.0],
  valC1: 0,
  valC2: 1,
  val1: 1.0,
  val2: 0.0,
  kvCurrent: 1,
  roopVal: 0,
  begin: null,
  afterTransitionInsertInterval: 1500,
  backVisualState: null
};

GlobalBg.init = function() {
  GlobalBg.El = document.createElement("div");
  GlobalBg.El.setAttribute("id", "bg");
  SHIMA_PORTFOLIO.stack.bundledScript.parentNode.insertBefore(
    GlobalBg.El,
    SHIMA_PORTFOLIO.stack.bundledScript
  );
  GlobalBg.setUniforms();
};

GlobalBg.setUniforms = function() {
  SHIMA_PORTFOLIO.Home.bgTexLoad.crossOrigin = "*";
  SHIMA_PORTFOLIO.Home.bgTexLoad.needsUpdate = true;
  GlobalBg.Tex.top1 = SHIMA_PORTFOLIO.Home.globalSlide[0];
  GlobalBg.Tex.top2 = SHIMA_PORTFOLIO.Home.globalSlide[1];
  GlobalBg.Tex.top3 = SHIMA_PORTFOLIO.Home.globalSlide[2];
  // GlobalBg.Tex.top4 = SHIMA_PORTFOLIO.Home.globalSlide[3];
  // GlobalBg.Tex.about = SHIMA_PORTFOLIO.Home.globalSlide[4];
  // GlobalBg.Tex.contact = SHIMA_PORTFOLIO.Home.globalSlide[5];
  // GlobalBg.Tex.dc = SHIMA_PORTFOLIO.Home.globalSlide[6];
  GlobalBg.Tex.about = SHIMA_PORTFOLIO.Home.globalSlide[3];
  GlobalBg.Tex.contact = SHIMA_PORTFOLIO.Home.globalSlide[4];
  GlobalBg.Tex.dc = SHIMA_PORTFOLIO.Home.globalSlide[5];
  // for (let i=0,li=4;i<li;i++) {
  for (let i = 0, li = 3; i < li; i++) {
    GlobalBg.Tex.keyImages.push(SHIMA_PORTFOLIO.Home.globalSlide[i]);
  }
  // let tex = SHIMA_PORTFOLIO.Home.globalSlide[7];
  let tex = SHIMA_PORTFOLIO.Home.globalSlide[6];
  tex.r = THREE.RepeatWrapping;
  let Visual1, Visual2;
  if (SHIMA_PORTFOLIO.stack.backVisualEffect) {
    if (SHIMA_PORTFOLIO.stack.location == "/") {
      Visual1 = GlobalBg.Tex.top1;
    } else if (SHIMA_PORTFOLIO.stack.location == "/about/") {
      Visual1 = GlobalBg.Tex.about;
    } else if (SHIMA_PORTFOLIO.stack.location == "/contact/") {
      Visual1 = GlobalBg.Tex.contact;
    }
    Visual2 = GlobalBg.Tex.top2;
  } else if (!SHIMA_PORTFOLIO.stack.backVisualEffect) {
    Visual1 = Visual2 = GlobalBg.Tex.dc;
  }
  GlobalBg.uniforms = {
    uKv1: { type: "t", value: Visual1 },
    uKv2: { type: "t", value: Visual2 },
    uTex: { type: "t", value: tex },
    uResolution: { type: "v2", value: new THREE.Vector2(0, 0) },
    uImgResolution: {
      type: "v2",
      value: new THREE.Vector2(GlobalBg.S.imgWidth, GlobalBg.S.imgHeight)
    },
    uTransFactor_1: { type: "f", value: 1.0 },
    uTransFactor_2: { type: "f", value: 0.0 },
    uMix: { type: "f", value: 1.0 },
    uIsStay: { type: "b", value: true },
    uIsSmt: { type: "b", value: false },
    uTime: { type: "f", value: 0.0 },
    uIsRetina: { type: "b", value: false },
    uXdirectionGap: { type: "f", value: 0.0 },
    uYdirectionGap: { type: "f", value: 0.0 }
  };

  GlobalBg.setScene();
};

GlobalBg.setScene = function() {
  GlobalBg.Three.geometry = new THREE.PlaneBufferGeometry(2, 2);
  GlobalBg.Three.material = new THREE.RawShaderMaterial({
    uniforms: GlobalBg.uniforms,
    vertexShader: vert,
    fragmentShader: frag,
    transparent: true
  });
  GlobalBg.Three.camera = new THREE.PerspectiveCamera(70, 1, 1, 1000);
  GlobalBg.Three.camera.aspect =
    SHIMA_PORTFOLIO.func.clientSize().H / SHIMA_PORTFOLIO.func.clientSize().W;
  GlobalBg.Three.mesh = new THREE.Mesh(
    GlobalBg.Three.geometry,
    GlobalBg.Three.material
  );
  GlobalBg.Three.scene.add(GlobalBg.Three.mesh);
  GlobalBg.Three.scene.add(GlobalBg.Three.camera);
  GlobalBg.El.appendChild(SHIMA_PORTFOLIO.three.RENDERER_MAIN.domElement);
  GlobalBg.resize();
  SHIMA_PORTFOLIO.three.RENDERER_MAIN.setClearColor(0xdcdcdc, 0.0);
  SHIMA_PORTFOLIO.three.RENDERER_MAIN.render(
    GlobalBg.Three.scene,
    GlobalBg.Three.camera
  );
  let bgBuffer = SHIMA_PORTFOLIO.stack.location == "/about/" ? 2000 : 1000;
  let startRenderingTimer = setInterval(function() {
    if (SHIMA_PORTFOLIO.stack.isRenderStart) {
      clearInterval(startRenderingTimer);
      GlobalBg.P.backVisualState = SHIMA_PORTFOLIO.stack.backVisualEffect;
      SHIMA_PORTFOLIO.stack.activeLocation = SHIMA_PORTFOLIO.stack.location;
      SHIMA_PORTFOLIO.stack.locationExclProcess =
        SHIMA_PORTFOLIO.stack.location;
      if (SHIMA_PORTFOLIO.stack.location == "/") GlobalBg.roopTransition();
      GlobalBg.render();
    }
  }, bgBuffer);
};

GlobalBg.resize = function() {
  let W = 0;
  let H = 0;
  let directionGapRaito = 1.08;
  window.devicePixelRatio == 2
    ? (GlobalBg.uniforms.uIsRetina.value = true)
    : (GlobalBg.uniforms.uIsRetina.value = false);
  W = SHIMA_PORTFOLIO.func.clientSize().W;
  if (
    SHIMA_PORTFOLIO.stack.isSmt == true &&
    SHIMA_PORTFOLIO.stack.location == "/about/"
  )
    H = SHIMA_PORTFOLIO.func.clientSize().H * 0.32;
  else {
    H = SHIMA_PORTFOLIO.func.clientSize().H;
  }
  SHIMA_PORTFOLIO.three.RENDERER_MAIN.setPixelRatio(
    window.devicePixelRatio ? window.devicePixelRatio : 1
  );
  GlobalBg.Three.camera.updateProjectionMatrix();
  GlobalBg.uniforms.uResolution.value.x = W * directionGapRaito;
  GlobalBg.uniforms.uResolution.value.y = H * directionGapRaito;
  GlobalBg.uniforms.uXdirectionGap.value = 0.5 * (directionGapRaito - 1.0);
  GlobalBg.uniforms.uYdirectionGap.value = 0.5 * (directionGapRaito - 1.0);
  SHIMA_PORTFOLIO.three.RENDERER_MAIN.setSize(
    W * directionGapRaito,
    H * directionGapRaito
  ); // renderer size
};

GlobalBg.roopTransition = function() {
  let interval = 7500;
  GlobalBg.Timer.gb = setInterval(function() {
    GlobalBg.P.c++;
    GlobalBg.P.c >= 1
      ? (GlobalBg.uniforms.uIsStay.value = false)
      : (GlobalBg.uniforms.uIsStay.value = true);
    GlobalBg.P.kvCurrent++;
    GlobalBg.P.prevSlide = GlobalBg.P.nextSlide;
    if (GlobalBg.P.kvCurrent >= 4) GlobalBg.P.kvCurrent = 1;
    SHIMA_PORTFOLIO.stack.kvCurrent = GlobalBg.P.kvCurrent;

    GlobalBg.P.nextSlide++;
    GlobalBg.P.nextSlide =
      GlobalBg.P.nextSlide >= 4
        ? (GlobalBg.P.nextSlide = 1)
        : GlobalBg.P.nextSlide++;

    GlobalBg.uniforms.uTransFactor_1.value < 0
      ? (GlobalBg.P.valC1 = 0)
      : GlobalBg.P.valC1++;
    GlobalBg.P.val1 = GlobalBg.P.valSet[GlobalBg.P.valC1];
    GlobalBg.uniforms.uTransFactor_2.value < 0
      ? (GlobalBg.P.valC2 = 0)
      : GlobalBg.P.valC2++;
    GlobalBg.P.val2 = GlobalBg.P.valSet[GlobalBg.P.valC2];

    let s1, s2; // prev next
    if (GlobalBg.P.val == 0) {
      s1 = GlobalBg.P.nextSlide;
      s2 = GlobalBg.P.prevSlide;
    } else {
      s1 = GlobalBg.P.prevSlide;
      s2 = GlobalBg.P.nextSlide;
    }
    GlobalBg.uniforms.uKv1.value = GlobalBg.Tex.keyImages[s2 - 1];
    GlobalBg.uniforms.uKv2.value = GlobalBg.Tex.keyImages[s1 - 1];
    GlobalBg.resetUpdatedValues();
    if (SHIMA_PORTFOLIO.stack.activeLocation == "/") GlobalBg.topPager();
  }, interval);
};

GlobalBg.topPager = function() {
  let li = document.querySelectorAll("#pager ul li");
  li[GlobalBg.P.prevSlide - 1].classList.remove("_on");
  // console.log(li[GlobalBg.P.nextSlide - 1]);
  li[GlobalBg.P.nextSlide - 1].classList.add("_on");
};

GlobalBg.floating = function() {
  setInterval(function() {
    GlobalBg.uniforms.uTime.value += 1.0;
  }, 40);
};

GlobalBg.render = function() {
  let interval = 35;
  GlobalBg.Timer.transition = setInterval(function() {
    GlobalBg.update();
  }, interval);
};

GlobalBg.update = function() {
  if (GlobalBg.P.begin == null) GlobalBg.P.begin = new Date().getTime();
  if (GlobalBg.P.val1 === void 0 || GlobalBg.P.val2 === void 0)
    GlobalBg.P.val1 = GlobalBg.P.val2 = 1.0;
  // GlobalBg.uniforms.uTime.value += 1.0;
  if (SHIMA_PORTFOLIO.stack.isPageTransition) {
    SHIMA_PORTFOLIO.stack.isPageTransition = false;
    SHIMA_PORTFOLIO.Works.first = false;
    GlobalBg.uniforms.uIsStay.value == true
      ? (GlobalBg.uniforms.uIsStay.value = false)
      : (GlobalBg.uniforms.uIsStay.value = false);
    // 背景Effect「なし」→「あり」へ
    if (
      SHIMA_PORTFOLIO.stack.backVisualEffect == true &&
      GlobalBg.P.backVisualState == false
    ) {
      GlobalBg.P.backVisualState = SHIMA_PORTFOLIO.stack.backVisualEffect;
      clearInterval(GlobalBg.Timer.gb);
      GlobalBg.setImageGap();
      GlobalBg.uniforms.uKv1.value = GlobalBg.Tex.dc;
      GlobalBg.uniforms.uKv2.value = GlobalBg.Tex.dc;
      GlobalBg.resetUpdatedValues();
      setTimeout(function() {
        GlobalBg.resize();
        GlobalBg.setImageGap();
        if (SHIMA_PORTFOLIO.stack.location == "/") {
          GlobalBg.P.prevSlide = 0;
          GlobalBg.P.nextSlide = 1;
          GlobalBg.uniforms.uKv1.value =
            GlobalBg.P.val == 1 ? GlobalBg.Tex.top1 : GlobalBg.Tex.dc;
          GlobalBg.uniforms.uKv2.value =
            GlobalBg.P.val == 1 ? GlobalBg.Tex.dc : GlobalBg.Tex.top1;
          GlobalBg.roopTransition();
        }
        GlobalBg.setPageImage();
        GlobalBg.resetUpdatedValues();
      }, GlobalBg.P.afterTransitionInsertInterval);
    }
    // 背景Effect「あり」→「なし」へ
    else if (
      SHIMA_PORTFOLIO.stack.backVisualEffect == false &&
      GlobalBg.P.backVisualState == true
    ) {
      GlobalBg.P.backVisualState = SHIMA_PORTFOLIO.stack.backVisualEffect;
      clearInterval(GlobalBg.Timer.gb);
      GlobalBg.setImageGap();
      if (SHIMA_PORTFOLIO.stack.activeLocation == "/") {
        GlobalBg.P.prevSlide = GlobalBg.P.nextSlide;
        GlobalBg.uniforms.uKv1.value =
          GlobalBg.P.val == 1
            ? GlobalBg.Tex.dc
            : GlobalBg.Tex.keyImages[GlobalBg.P.prevSlide - 1];
        GlobalBg.uniforms.uKv2.value =
          GlobalBg.P.val == 1
            ? GlobalBg.Tex.keyImages[GlobalBg.P.prevSlide - 1]
            : GlobalBg.Tex.dc;
      }
      GlobalBg.activePageImage();
      GlobalBg.resetUpdatedValues();
    }
    // 背景Effect「なし」→「なし」へ
    else if (
      SHIMA_PORTFOLIO.stack.backVisualEffect == false &&
      GlobalBg.P.backVisualState == false
    ) {
      GlobalBg.P.backVisualState = SHIMA_PORTFOLIO.stack.backVisualEffect;
      clearInterval(GlobalBg.Timer.gb);
      GlobalBg.setImageGap();
      GlobalBg.uniforms.uKv1.value = GlobalBg.Tex.dc;
      GlobalBg.uniforms.uKv2.value = GlobalBg.Tex.dc;
      GlobalBg.resetUpdatedValues();
    }
    // 背景Effect「あり」→「あり」へ
    else if (
      SHIMA_PORTFOLIO.stack.backVisualEffect == true &&
      GlobalBg.P.backVisualState == true
    ) {
      GlobalBg.P.backVisualState = SHIMA_PORTFOLIO.stack.backVisualEffect;
      clearInterval(GlobalBg.Timer.gb);
      GlobalBg.setImageGap();
      // topからくるとき
      if (SHIMA_PORTFOLIO.stack.activeLocation == "/") {
        GlobalBg.P.prevSlide = GlobalBg.P.nextSlide;
        GlobalBg.uniforms.uKv1.value =
          GlobalBg.P.val == 1
            ? GlobalBg.Tex.dc
            : GlobalBg.Tex.keyImages[GlobalBg.P.prevSlide - 1];
        GlobalBg.uniforms.uKv2.value =
          GlobalBg.P.val == 1
            ? GlobalBg.Tex.keyImages[GlobalBg.P.prevSlide - 1]
            : GlobalBg.Tex.dc;
      }
      GlobalBg.activePageImage();
      GlobalBg.resetUpdatedValues();
      setTimeout(function() {
        GlobalBg.resize();
        GlobalBg.setImageGap();
        if (SHIMA_PORTFOLIO.stack.location == "/") {
          // 1枚目からスタート
          GlobalBg.P.prevSlide = 0;
          GlobalBg.P.nextSlide = 1;
          GlobalBg.uniforms.uKv1.value =
            GlobalBg.P.val == 1 ? GlobalBg.Tex.top1 : GlobalBg.Tex.dc;
          GlobalBg.uniforms.uKv2.value =
            GlobalBg.P.val == 1 ? GlobalBg.Tex.dc : GlobalBg.Tex.top1;
          GlobalBg.roopTransition();
        }
        GlobalBg.setPageImage();
        GlobalBg.resetUpdatedValues();
      }, GlobalBg.P.afterTransitionInsertInterval);
    }
    SHIMA_PORTFOLIO.stack.activeLocation = SHIMA_PORTFOLIO.stack.location;
  }

  if (GlobalBg.P.roopVal < 1) {
    let passed = new Date().getTime() - GlobalBg.P.begin;
    GlobalBg.P.roopVal = passed / 2600;
    GlobalBg.uniforms.uTransFactor_1.value =
      GlobalBg.P.val1 - Ease.EaseOutQuart(GlobalBg.P.roopVal);
    GlobalBg.uniforms.uTransFactor_2.value =
      GlobalBg.P.val2 - Ease.EaseOutQuart(GlobalBg.P.roopVal);
    TweenMax.to(
      GlobalBg.uniforms.uMix,
      GlobalBg.uniforms.uIsStay.value == false ? 0.6 : 2.5,
      { ease: "linear", value: GlobalBg.P.val }
    );
  } else if (GlobalBg.P.roopVal >= 1)
    SHIMA_PORTFOLIO.stack.backVisualEffect == false ? STOP_RENDER() : "";
  SHIMA_PORTFOLIO.three.RENDERER_MAIN.setClearColor(0xdcdcdc, 0.0);
  SHIMA_PORTFOLIO.three.RENDERER_MAIN.render(
    GlobalBg.Three.scene,
    GlobalBg.Three.camera
  );
};

GlobalBg.setPageImage = function() {
  if (SHIMA_PORTFOLIO.stack.location == "/about/") {
    GlobalBg.uniforms.uKv1.value =
      GlobalBg.P.val == 1 ? GlobalBg.Tex.about : GlobalBg.Tex.dc;
    GlobalBg.uniforms.uKv2.value =
      GlobalBg.P.val == 1 ? GlobalBg.Tex.dc : GlobalBg.Tex.about;
  } else if (SHIMA_PORTFOLIO.stack.location == "/contact/") {
    if (!SHIMA_PORTFOLIO.stack.isSmt) {
      GlobalBg.uniforms.uKv1.value =
        GlobalBg.P.val == 1 ? GlobalBg.Tex.contact : GlobalBg.Tex.dc;
      GlobalBg.uniforms.uKv2.value =
        GlobalBg.P.val == 1 ? GlobalBg.Tex.dc : GlobalBg.Tex.contact;
    }
  }
};

GlobalBg.activePageImage = function() {
  if (SHIMA_PORTFOLIO.stack.activeLocation == "/about/") {
    GlobalBg.uniforms.uKv1.value =
      GlobalBg.P.val == 1 ? GlobalBg.Tex.dc : GlobalBg.Tex.about;
    GlobalBg.uniforms.uKv2.value =
      GlobalBg.P.val == 1 ? GlobalBg.Tex.about : GlobalBg.Tex.dc;
  } else if (SHIMA_PORTFOLIO.stack.activeLocation == "/contact/") {
    if (!SHIMA_PORTFOLIO.stack.isSmt) {
      GlobalBg.uniforms.uKv1.value =
        GlobalBg.P.val == 1 ? GlobalBg.Tex.dc : GlobalBg.Tex.contact;
      GlobalBg.uniforms.uKv2.value =
        GlobalBg.P.val == 1 ? GlobalBg.Tex.contact : GlobalBg.Tex.dc;
    }
  }
};

GlobalBg.setImageGap = function() {
  GlobalBg.uniforms.uTransFactor_1.value < 0
    ? (GlobalBg.P.valC1 = 0)
    : GlobalBg.P.valC1++;
  GlobalBg.P.val1 = GlobalBg.P.valSet[GlobalBg.P.valC1];
  GlobalBg.uniforms.uTransFactor_2.value < 0
    ? (GlobalBg.P.valC2 = 0)
    : GlobalBg.P.valC2++;
  GlobalBg.P.val2 = GlobalBg.P.valSet[GlobalBg.P.valC2];
};

GlobalBg.resetUpdatedValues = function() {
  GlobalBg.P.begin = new Date().getTime();
  GlobalBg.P.roopVal = 0;
  GlobalBg.P.val == 1 ? (GlobalBg.P.val = 0) : (GlobalBg.P.val = 1);
};

const MAIN_RESIZE = function() {
  GlobalBg.resize();
};
const PAGER_ACTION = function() {
  if (GlobalBg.P.kvCurrent !== SHIMA_PORTFOLIO.stack.kvCurrent) {
    GlobalBg.P.kvCurrent = SHIMA_PORTFOLIO.stack.kvCurrent;
    GlobalBg.uniforms.uIsStay.value == true
      ? (GlobalBg.uniforms.uIsStay.value = false)
      : (GlobalBg.uniforms.uIsStay.value = false);
    clearInterval(GlobalBg.Timer.gb);
    GlobalBg.P.prevSlide = GlobalBg.P.nextSlide;
    GlobalBg.P.nextSlide = SHIMA_PORTFOLIO.stack.kvCurrent;
    GlobalBg.uniforms.uTransFactor_1.value < 0
      ? (GlobalBg.P.valC1 = 0)
      : GlobalBg.P.valC1++;
    GlobalBg.P.val1 = GlobalBg.P.valSet[GlobalBg.P.valC1];
    GlobalBg.uniforms.uTransFactor_2.value < 0
      ? (GlobalBg.P.valC2 = 0)
      : GlobalBg.P.valC2++;
    GlobalBg.P.val2 = GlobalBg.P.valSet[GlobalBg.P.valC2];
    GlobalBg.uniforms.uKv1.value =
      GlobalBg.P.val == 1
        ? GlobalBg.Tex.keyImages[GlobalBg.P.nextSlide - 1]
        : GlobalBg.Tex.keyImages[GlobalBg.P.prevSlide - 1];
    GlobalBg.uniforms.uKv2.value =
      GlobalBg.P.val == 1
        ? GlobalBg.Tex.keyImages[GlobalBg.P.prevSlide - 1]
        : GlobalBg.Tex.keyImages[GlobalBg.P.nextSlide - 1];
    GlobalBg.resetUpdatedValues();
    GlobalBg.roopTransition();
  }
};
const STOP_RENDER = function() {
  clearInterval(GlobalBg.Timer.transition);
};
const RESTART_REDER = function() {
  GlobalBg.render();
};
const BG_START = function() {
  GlobalBg.init();
};

export { BG_START, STOP_RENDER, RESTART_REDER, MAIN_RESIZE, PAGER_ACTION };
