import React from 'react';
import ReactDOM from 'react-dom';
/**
 * @namespace React > Component > MainMenuUnder
 */
const MainMenuUnder = () => (
  <footer>
    <div id="footer">
      <div id="footerContent" className="content">
        <div className="footerLine line"></div>
        <div className="footerLineLeft"></div>
        <div className="sns">
          <p>Follow</p>
          <ul>
            <li><a href="https://twitter.com/_shimaphoto" target="_blank"></a></li>
            <li><a href="https://www.instagram.com/takatsugushimazaki/" target="_blank"></a></li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
);

export default MainMenuUnder;



