const Ease = {
  // linear ---
  Linear: function (P) {
    return P;
  },
  // EaseOut ---
  EaseOutQuint: function(P) {
    return 1+(--P)*P*P*P*P;
  },
  EaseOutQuart: function(P) {
    return 1-(--P)*P*P*P;
  },
  EaseOutCubic: function(P) {
    return (--P)*P*P+1;
  },
  // EaseInOut ---
  EaseInOutQuad: function (P) {
    return P<.5 ? 2*P*P : -1+(4-2*P)*P;
  },
  EaseInOutQuint: function(P) {
    return P < 0.5 ? 16*P*P*P*P*P : 1+16*(--P)*P*P*P*P;
  },
  EaseInOutQuart: function (P) {
    return P<.5 ? 8*P*P*P*P : 1-8*(--P)*P*P*P;
  },
  EaseInOutCubic: function(P) {
    return P<.5 ? 4*P*P*P : (P-1)*(2*P-2)*(2*P-2)+1;
  }
};

export { Ease };