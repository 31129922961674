// MODULES //////////
import React from 'react';
import ReactDOM from 'react-dom';
// COMPONENT JSX //////////
import MainMenuUp from "./MainMenuUp";
import MainMenuUnder from "./MainMenuUnder";
const Header = () => <MainMenuUp />
const Footer = () => <MainMenuUnder />
const Layout = ({ children }) => (
  <div id="rootContents">
    <Header />
    { children }
    <Footer />
  </div>
);


export default Layout;
