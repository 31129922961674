import React from 'react';
import ReactDOM from 'react-dom';
import { Heading } from './../component/PageHeading.jsx';
import { ImagesDom } from './../../js/parts/loadImages';
/**
 * @namespace React > Component > Layout_Contact
 */
export class Layout_Contact extends React.Component {
  componentWillMount() {
    if (SHIMA_PORTFOLIO.Works.imagesDom.length==0) ImagesDom.init();
  }
  componentDidMount() {
    document.title="contact - Takatsugu Shimazaki Photography | 写真家　島崎隆次";
    setTimeout(function() {
      SHIMA_PORTFOLIO.stack.isFirst=false;
      SHIMA_PORTFOLIO.stack.body.classList.remove("_evtOff");
    },SHIMA_PORTFOLIO.stack.isFirst==true ? 5400 : 2400);
    if (SHIMA_PORTFOLIO.Works.imagesDom.length==0) ImagesDom.init();
    SHIMA_PORTFOLIO.stack.loaded=true;
  }
  render(){
    return (
      <div id={ this.props.cur }>
        <div className={ this.props.cur }>
          <div className="contact">
            <div className="contactTtl">
              <Heading current={ this.props.cur } />
              <div><a href="mailto:info@takatsugushimazaki.com" target="_blank">info@takatsugushimazaki.com</a></div>
            </div>
            <div className="linkLst">
              <ul className="linkLstSns">
                <li><a href="https://twitter.com/_shimaphoto" target="_blank">X</a></li>
                <li><a href="https://www.instagram.com/takatsugushimazaki/" target="_blank">instagram</a></li>
              </ul>
              {/* <ul className="linkLstCompany">
                <li><a href="https://www.sonicjam.co.jp/" target="_blank">sonicjam</a></li>
                <li><a href="http://sonicjam.studio/" target="_blank">sonicjam.studio</a></li>
              </ul> */}
            </div>
          </div>
          <div className="__hide">
            <ul className="__hide">{ SHIMA_PORTFOLIO.Works.imagesDom[SHIMA_PORTFOLIO.Works.activeCategoryIdx] }</ul>
          </div>
        </div>
      </div>
    )
  }
}

