// MODULES //////////
import glslify from 'glslify';
import * as THREE from 'three';
import { vert, frag } from './shader/works/fullScreen';
// LIBS //////////
import TweenMax from 'gsap/TweenMax';
// EASE JAVASCRIPT ////////
import { Ease } from './../easing';
// BACKGROUND ANIM HANDLING JAVASCRIPT ////////
import { STOP_RENDER, RESTART_REDER } from './main';

let WORKS_CLICK = {
  stack: {
    El : null,
    trigger: null,
    IMG_WIDTH : 2000,
    IMG_HEIGHT: 1280,
    TEXTURE : '/image/common/texture/tex.jpg',
    TARGET_WORKS : '/image/common/dcdcdc.jpg',
    TEX : null,
    ACTIVE_IMG : null,
    PI : Math.PI,
    scene : null,
    camera : null,
    geometry : null,
    material : null,
    mesh : null,
    uniforms : null,

    begin : null,
    passed : 0,
    roopVal : 0,
  },
  func: {
    init : function() {
      WORKS_CLICK.stack.scene = new THREE.Scene();
      WORKS_CLICK.stack.camera = new THREE.PerspectiveCamera(
        70,
        SHIMA_PORTFOLIO.func.clientSize().H / SHIMA_PORTFOLIO.func.clientSize().W, 
        1,
        1000
      );
      WORKS_CLICK.func.setUniforms();
    },
    setUniforms : function() {
      WORKS_CLICK.stack.geometry = new THREE.PlaneBufferGeometry(2, 2);
      WORKS_CLICK.stack.camera.aspect = SHIMA_PORTFOLIO.func.clientSize().H / SHIMA_PORTFOLIO.func.clientSize().W;
      WORKS_CLICK.stack.scene.add(WORKS_CLICK.stack.camera);
      WORKS_CLICK.stack.uniforms = {
        uWorks : {
          type  : "t",
          value : ""
        },
        uTex: {
          type  : "t",
          value : WORKS_CLICK.stack.TEX
        },
        uTrans: {
          type  : "f",
          value : 0.1
        },
        uResolution : {
          type  : "v2",
          value : new THREE.Vector2(0, 0)
        },
        uImgResolution: {
          type  : "v2",
          value : new THREE.Vector2(WORKS_CLICK.stack.IMG_WIDTH, WORKS_CLICK.stack.IMG_HEIGHT)
        },
        uTime: {
          type  : "f",
          value : 0.0
        },
        uIsRetina: {
          type  : "b",
          value : false
        }
      };
      WORKS_CLICK.stack.material = new THREE.RawShaderMaterial({
        uniforms       : WORKS_CLICK.stack.uniforms,
        vertexShader   : vert,
        fragmentShader : frag,
        transparent    : true
      });
      WORKS_CLICK.stack.mesh=new THREE.Mesh(WORKS_CLICK.stack.geometry, WORKS_CLICK.stack.material);
      WORKS_CLICK.stack.scene.add(WORKS_CLICK.stack.mesh);
      WORKS_CLICK.stack.El=document.getElementById('works__full');
      WORKS_CLICK.stack.trigger=document.getElementById('works__trigger');
      WORKS_CLICK.stack.El.appendChild(SHIMA_PORTFOLIO.three.RENDERER_WORKS_CLICK.domElement);

      SHIMA_PORTFOLIO.Works.isWorksClicked=true;
      WORKS_CLICK.stack.roopVal=0;
      WORKS_CLICK.stack.uniforms.uTrans.value=0.7;

      WORKS_CLICK.func.resize();
      WORKS_CLICK.func.render();
    },
    resize: function() {
      let W, H = 0;
      // let directionGapRaito = 1.06;
      if (WORKS_CLICK.stack.uniforms.uIsRetina.value !==null) {
        (window.devicePixelRatio==2) ? WORKS_CLICK.stack.uniforms.uIsRetina.value=true : WORKS_CLICK.stack.uniforms.uIsRetina.value=false;
      }
      W=SHIMA_PORTFOLIO.func.clientSize().W;
      H=(!SHIMA_PORTFOLIO.stack.isSmt) ? SHIMA_PORTFOLIO.func.clientSize().H : (WORKS_CLICK.stack.IMG_HEIGHT*W)/WORKS_CLICK.stack.IMG_WIDTH;
      
      SHIMA_PORTFOLIO.three.RENDERER_WORKS_CLICK.setPixelRatio(window.devicePixelRatio ? window.devicePixelRatio : 1);
      WORKS_CLICK.stack.camera.updateProjectionMatrix();
      WORKS_CLICK.stack.uniforms.uResolution.value.x = W;
      WORKS_CLICK.stack.uniforms.uResolution.value.y = H;
      // WORKS_CLICK.stack.uniforms.uXdirectionGap.value = 0.5*(directionGapRaito - 1.0);
      // WORKS_CLICK.stack.uniforms.uYdirectionGap.value = 0.5*(directionGapRaito - 1.0);
      SHIMA_PORTFOLIO.three.RENDERER_WORKS_CLICK.setSize(W, H);
      WORKS_CLICK.func.render();
    },
    itemClicked : function(IDX,P) {
      let project=document.getElementById("works__project");
      project.innerHTML=P;
      WORKS_CLICK.stack.ACTIVE_IMG=SHIMA_PORTFOLIO.Works.EACH_LOADED_TEXTURES[SHIMA_PORTFOLIO.Works.activeCategoryIdx][IDX];
      WORKS_CLICK.stack.uniforms.uWorks.value=WORKS_CLICK.stack.ACTIVE_IMG;
      if (SHIMA_PORTFOLIO.stack.isSmt) WORKS_CLICK.stack.trigger.classList.add('_on');
      WORKS_CLICK.func.update();
    },
    resetVals : function() {
      SHIMA_PORTFOLIO.Works.isWorksClicked=true;
      WORKS_CLICK.stack.roopVal=0;
      WORKS_CLICK.stack.uniforms.uTrans.value=0.1;
      // cursor.classList.remove('_open');
      // WORKS_CLICK.stack.trigger.classList.remove('_on');
    },
    itemClose: function() {
      let cursor=document.getElementById("works__cursor");
      TweenMax.to(
        WORKS_CLICK.stack.El, .8,
        { 
          autoAlpha: 0,
          onComplete: function() {
            SHIMA_PORTFOLIO.Works.isWorksClicked=true;
            WORKS_CLICK.stack.roopVal=0;
            WORKS_CLICK.stack.uniforms.uTrans.value=0.1;
            cursor.classList.remove('_on','_open');
            WORKS_CLICK.stack.trigger.classList.remove('_on');
          }
        }
      );
    },
    update : function() {
      let buffer=80;
      setTimeout(function() {
        WORKS_CLICK.func.roop();
        TweenMax.set(WORKS_CLICK.stack.El, { autoAlpha: 1 });
      }, buffer);
    },
    roop : function() {
      WORKS_CLICK.stack.begin=new Date().getTime();
      let roopAni=function() {
        if (WORKS_CLICK.stack.roopVal < 1) {
          WORKS_CLICK.stack.passed = new Date().getTime() - WORKS_CLICK.stack.begin;
          WORKS_CLICK.stack.roopVal = WORKS_CLICK.stack.passed / 1800;
          WORKS_CLICK.stack.uniforms.uTrans.value = 0.1*(1.0 - Ease.EaseOutCubic(WORKS_CLICK.stack.roopVal));
        }
        else if (WORKS_CLICK.stack.roopVal>=1) {
          WORKS_CLICK.stack.uniforms.uTime.value = 0.0;
          SHIMA_PORTFOLIO.Works.isWorksClicked=true;
        }
        WORKS_CLICK.func.render();
        WORKS_CLICK.stack.req=requestAnimationFrame(roopAni);
        if (WORKS_CLICK.stack.roopVal>=1) cancelAnimationFrame(WORKS_CLICK.stack.req);
      };
      roopAni();
    },
    render : function() {
      SHIMA_PORTFOLIO.three.RENDERER_WORKS_CLICK.setClearColor(0xdcdcdc, 0.0);
      SHIMA_PORTFOLIO.three.RENDERER_WORKS_CLICK.render(WORKS_CLICK.stack.scene, WORKS_CLICK.stack.camera);
    },
  }
};

const WORKS_ON = function(I,P) {
  WORKS_CLICK.func.itemClicked(I,P);
}
const WORKS_OFF = function() {
  WORKS_CLICK.func.itemClose();
}
const WORKSFULL_RESET = function() {
  WORKS_CLICK.func.resetVals();
}
const SET_WORKS_FULLSCREEN = function() {
  WORKS_CLICK.func.init();
}
const ITEMS_CLICK_RESIZE = function() {
  WORKS_CLICK.func.resize();
}


export { SET_WORKS_FULLSCREEN, WORKS_ON, WORKS_OFF, ITEMS_CLICK_RESIZE, WORKSFULL_RESET };


