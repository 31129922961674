// MODULES //////////
import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import TweenMax from 'gsap/TweenMax';
/**
 * @namespace React > Component > MainMenuUp
 */
const MainMenuUp = () => {
  let pageSet = [ "about", "works", "contact" ];
  let currentLocation = location.pathname.split('/')[1] || '/';
  return (
    <header>
      <div id="header">
        <div id="headerContent" className="content">
          <div className="headerContent">
            <div id="headerContent__ttl">
              <Link to="/" data-background="1" data-page="/" onClick={ checkBackVisualEffect }>
                <h1>島崎 隆次</h1>
                <p>&copy;TAKATSUGUSHIMAZAKI</p>
              </Link>
            </div>
            <div id="headerContent__menu">
              <nav>
                <ul>
                  <li
                    data-background="1"
                    data-page="/about/"
                    className={ (currentLocation == pageSet[0] ? '_on' : '') }
                    onClick={ checkBackVisualEffect }>
                    <Link to="/about/">
                      <span>
                        <span className="t">About</span>
                        <span className="t_hor">About</span>
                      </span>
                    </Link>
                  </li>
                  <li
                    data-background="0"
                    data-page="/works/"
                    className={ (currentLocation == pageSet[1] ? '_on' : '') }
                    onClick={ checkBackVisualEffect }>
                    <Link to="/works/">
                      <span>
                        <span className="t">Works</span>
                        <span className="t_hor">Works</span>
                      </span>
                    </Link>
                  </li>
                  {/* <li>
                    
                    <a href="/https://www.takatsugushimazaki-store.com/" target="_blank">
                      <span>
                        <span className="t">Store</span>
                        <span className="t_hor">Store</span>
                      </span>
                    </a>
                  </li>
                   */}
                  <li
                    data-background="1"
                    data-page="/contact/"
                    className={ (currentLocation == pageSet[2] ? '_on' : '') }
                    onClick={ checkBackVisualEffect }>
                    <Link to="/contact/">
                      <span>
                        <span className="t">Contact</span>
                        <span className="t_hor">Contact</span>
                      </span>
                    </Link>
                  </li>
                </ul>
              </nav>
              <div className="headerContent__lineTop"></div>
              <div className="headerContent__lineLeft"></div>
              <div className="headerContent__lineBottom"></div>
              <span id="headerContent__active" className={ currentLocation=="/" ? "_home" : "_"+currentLocation }></span>
            </div>
            <div id="headerContent__trigger" onClick={ triggerClicked }>
              <a href="javascript:void(0);">
                <span>
                  <span><span></span></span>
                  <span><span></span></span>
                  <span><span></span></span>
                </span>
              </a>
            </div>
          </div>
          <div className="headerLine line"></div>
        </div>
      </div>
    </header>
  )
};
const triggerClicked = function(e) {
  let trigger = document.querySelector('#headerContent__trigger');
  let headerContent=document.querySelector('.headerContent');
  trigger.classList.toggle('_on');
  headerContent.classList.toggle('_on');
};
const checkBackVisualEffect = function(e) {
  let trigger = document.querySelector('#headerContent__trigger');
  let headerContent=document.querySelector('.headerContent');
  trigger.classList.remove('_on');
  headerContent.classList.remove('_on');
  if (SHIMA_PORTFOLIO.stack.directory==e.currentTarget.getAttribute('data-page')) return;
  const N=e.currentTarget.getAttribute('data-background');
  (N==1) ? SHIMA_PORTFOLIO.stack.backVisualEffect=true : SHIMA_PORTFOLIO.stack.backVisualEffect=false;
};



export default MainMenuUp;




