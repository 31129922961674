import glslify from 'glslify';
/* 
Vertex 
*/
export const vert = glslify(`
  attribute vec3 position;
  attribute vec2 texCoord;
  attribute vec2 uv;
  varying vec2 vUv;
  void main() {
    vUv = uv;
    gl_Position = vec4(position, 1.0);
  }
`);
/*
Fragment
*/
export const frag = glslify(`
  precision mediump float;
  varying vec2 vUv;
  // varying vec2 vTexCoord;
  uniform sampler2D uKv1;
  uniform sampler2D uKv2;
  uniform sampler2D uTex;
  uniform float uTransFactor_1;
  uniform float uTransFactor_2;
  uniform float uMix;   
  uniform vec2 uResolution;
  uniform vec2 uImgResolution;
  uniform bool uIsStay;
  uniform float uTime;
  uniform bool uIsRetina;
  uniform float uXdirectionGap;
  uniform float uYdirectionGap;

  vec4 color1;
  vec4 color2;
  vec4 dest;
  float gap = 0.068;
  float PixelRatio = 0.5;
  vec3 KEYCOLOR = vec3(0.862745098, 0.862745098, 0.862745098);
  void main(void) {
    // PixelRatio = (uIsRetina==true) ? 0.5 : 1.0;
    vec2 AspectRatio = vec2(
      min((uResolution.x / uResolution.y) / (uImgResolution.x / uImgResolution.y), 1.0),
      min((uResolution.y / uResolution.x) / (uImgResolution.y / uImgResolution.x), 1.0)
    );
    vec2 uv = vec2(
      vUv.x * AspectRatio.x + PixelRatio * (1.0 - AspectRatio.x),
      vUv.y * AspectRatio.y + PixelRatio * (1.0 - AspectRatio.y)
    );

    vec4 tex = texture2D(uTex, uv);

    float trans1 = gap*tex.r*uTransFactor_1;
    float trans2 = gap*tex.r*uTransFactor_2;
    float slideDist1 = uTransFactor_1*0.002;
    float slideDist2 = uTransFactor_2*0.002;
    float flootX = 0.020*cos(PixelRatio*0.010*uTime);
    float flootY = 0.021*sin(PixelRatio*0.010*uTime*0.70);

    color1 = texture2D(uKv1, vec2(uv.x + uXdirectionGap + slideDist1 + trans1 + flootX, uv.y - uYdirectionGap + flootY));
    color2 = texture2D(uKv2, vec2(uv.x + uXdirectionGap + slideDist2 + trans2 + flootX, uv.y - uYdirectionGap + flootY));
    dest = mix(color1, uIsStay==true ? vec4(KEYCOLOR, 1.0) : color2, uMix);

    gl_FragColor = dest;
  }
`);






