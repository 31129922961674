// MODULES //////////
import React from 'react';

const ImagesDom = {
  init: function() {
    let dom=[];
    for (let i=0,li=SHIMA_PORTFOLIO.Works.eachCategory.length;i<li;i++) {
      dom=[];
      for (let j=0,li_=SHIMA_PORTFOLIO.Works.eachCategory[i].length;j<li_;j++) {
        dom.push(
          <li
            key={ j }
            data-id={ j }
            data-url={ SHIMA_PORTFOLIO.Works.eachCategory[i][j]['work'] }
            data-project={ SHIMA_PORTFOLIO.Works.eachCategory[i][j]['project'] }
            className="item">
            <a href="javascript:void(0)"><img src={ SHIMA_PORTFOLIO.Works.eachCategory[i][j]['work'] }/></a>
          </li>
        );
      }
      SHIMA_PORTFOLIO.Works.imagesDom.push(dom);
    }
  }
};

export { ImagesDom };
