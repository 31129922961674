import React from 'react';
import ReactDOM from 'react-dom';
/**
 * @namespace React > Component > Heading
 */
export class Heading extends React.Component {
  constructor(props) {
    super(props);
    switch (props.current) {
      case 'Home':
        this.pageH = this.props[0];
        break;
      case 'About':
        this.pageH = this.props[1];
        break;
      case 'Works':
        // this.pageH = this.props[0];
        break;
      case 'Contact':
        this.pageH = this.props[3];
        break;
      default:
        break;
    }
  }
  render() {
    return (
      <h2 className="heading">{ this.pageH }</h2>
    )
  }
}

Heading.defaultProps = [
  "",
  "Profile",
  "",
  "Get in touch"
];

