import React from "react";
import ReactDOM from "react-dom";
import { PAGER_ACTION } from "./../../js/parts/effects/main";
import { ImagesDom } from "./../../js/parts/loadImages";
/**
 * @namespace React > Component > Layout_Home
 */
export class Layout_Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = { number: 1 };
    this.slideLen = 3;
    this.pagerClicked = this.pagerClicked.bind(this);
  }
  componentWillMount() {
    if (SHIMA_PORTFOLIO.Works.imagesDom.length == 0) ImagesDom.init();
  }
  componentDidMount() {
    document.title = "Takatsugu Shimazaki Photography | 写真家　島崎隆次";
    setTimeout(
      function() {
        SHIMA_PORTFOLIO.stack.isFirst = false;
        SHIMA_PORTFOLIO.stack.body.classList.remove("_evtOff");
      },
      SHIMA_PORTFOLIO.stack.isFirst == true ? 5400 : 2400
    );
    if (SHIMA_PORTFOLIO.Works.imagesDom.length == 0) ImagesDom.init();
    SHIMA_PORTFOLIO.stack.loaded = true;
  }
  render() {
    let lst = [];
    const { number } = this.state;
    for (let i = 1, li = this.slideLen; i <= li; i++) {
      lst.push(
        <li
          key={i}
          className={number == i ? "_on" : ""}
          onClick={this.pagerClicked}
          data-n={i}
        >
          <span>
            <span>{i}</span>
          </span>
        </li>
      );
    }
    return (
      <div id={this.props.cur}>
        <div className={this.props.cur}>
          <div id="pager">
            <ul>{lst}</ul>
          </div>
          <div className="__hide">
            <ul className="__hide">
              {
                SHIMA_PORTFOLIO.Works.imagesDom[
                  SHIMA_PORTFOLIO.Works.activeCategoryIdx
                ]
              }
            </ul>
          </div>
        </div>
      </div>
    );
  }
  pagerClicked(e) {
    let li = document.querySelectorAll("#pager ul li");
    const N = e.currentTarget.getAttribute("data-n");
    if (SHIMA_PORTFOLIO.stack.kvCurrent == N) return;
    SHIMA_PORTFOLIO.stack.kvCurrent = N;
    for (let i = 0, list = li.length; i < list; i++) {
      li[i].classList.remove("_on");
    }
    this.setState({ number: SHIMA_PORTFOLIO.stack.kvCurrent });
    PAGER_ACTION();
  }
}
