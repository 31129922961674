// MODULES //////////
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Router, Switch, Route, browserHistory, withRouter, Link } from "react-router-dom";
import { TransitionGroup, CSSTransition } from 'react-transition-group';
// PAGES JSX //////////
import { Layout_Home } from './pages/Layout_Home.jsx';
import { Layout_About } from './pages/Layout_About.jsx';
import { Layout_Works } from './pages/Layout_Works.jsx';
import { Layout_Contact } from './pages/Layout_Contact.jsx';
// COMPONENT JSX //////////
import Layout from "./component/Layout.jsx";
// GLSL HANDLING JAVASCRIPT //////////
import { BG_START, STOP_RENDER, RESTART_REDER } from './../js/parts/effects/main';

const App = ({ location }) => {
  const currentLocation = location.pathname.split('/')[1] || '/';
  const timeout = { enter: SHIMA_PORTFOLIO.stack.isAccess==true ? 4600 : 1500, exit: 500 }; //6600
  SHIMA_PORTFOLIO.stack.location=location.pathname;
  SHIMA_PORTFOLIO.stack.body.classList.add("_evtOff");
  (location.pathname == "/works/" || (SHIMA_PORTFOLIO.stack.isSmt==true && location.pathname == "/contact/")) ? SHIMA_PORTFOLIO.stack.backVisualEffect=false : SHIMA_PORTFOLIO.stack.backVisualEffect=true;
  checkAccessStatus();
  SHIMA_PORTFOLIO.stack.isAccess=false;
  SHIMA_PORTFOLIO.stack.locationExclusiveProcess=SHIMA_PORTFOLIO.stack.location;
  return (
    <Layout>
      <TransitionGroup component="main">
        <CSSTransition
          classNames={ SHIMA_PORTFOLIO.classNames }
          key={ currentLocation }
          timeout={ timeout }
          appear >
          <div id="pageContents">
            <Switch location={ location }>
              <Route exact path='/' render={props => (<Layout_Home cur={ 'Home' }/>)} />
              <Route path='/about/' render={props => (<Layout_About cur={ 'About' }/>)} />
              <Route path='/works/' render={props => (<Layout_Works cur={ 'Works' }/>)} />
              <Route path='/contact/' render={props => (<Layout_Contact cur={ 'Contact' }/>)} />
            </Switch>
          </div>
        </CSSTransition>
      </TransitionGroup>
    </Layout>
  )
};
const checkAccessStatus = () => {
  if (SHIMA_PORTFOLIO.stack.location==SHIMA_PORTFOLIO.stack.directory) {
    SHIMA_PORTFOLIO.stack.body.classList.remove("_evtOff");
    return;
  }
  SHIMA_PORTFOLIO.stack.directory=SHIMA_PORTFOLIO.stack.location;
  if (SHIMA_PORTFOLIO.stack.isAccess) BG_START(); // 初回 (背景)
  else if (!SHIMA_PORTFOLIO.stack.isAccess) {
    if (SHIMA_PORTFOLIO.stack.backVisualEffect) {
      if (SHIMA_PORTFOLIO.stack.locationExclusiveProcess !== SHIMA_PORTFOLIO.stack.location) {
        SHIMA_PORTFOLIO.stack.isPageTransition=true;
        STOP_RENDER();
        RESTART_REDER();
      }
    }
    else {
      if (SHIMA_PORTFOLIO.stack.locationExclusiveProcess !== SHIMA_PORTFOLIO.stack.location) {
        SHIMA_PORTFOLIO.stack.isPageTransition=true;
      }
    }
  }
};




export default withRouter(App);



