import React from 'react';
import ReactDOM from 'react-dom';
import { Heading } from './../component/PageHeading.jsx';
import { ImagesDom } from './../../js/parts/loadImages';
/**
 * @namespace React > Component > Layout_About
 */
export class Layout_About extends React.Component {
  componentWillMount() {
    if (SHIMA_PORTFOLIO.Works.imagesDom.length==0) ImagesDom.init();
  }
  componentDidMount() {
    document.title="about - Takatsugu Shimazaki Photography | 写真家　島崎隆次";
    setTimeout(function() {
      SHIMA_PORTFOLIO.stack.isFirst=false;
      SHIMA_PORTFOLIO.stack.body.classList.remove("_evtOff");
    },SHIMA_PORTFOLIO.stack.isFirst==true ? 5400 : 2400);
    SHIMA_PORTFOLIO.stack.loaded=true;
  }
  render(){
    return (
      <div id={ this.props.cur }>
        <div className={ this.props.cur }>
          <div className="about">
            <Heading current={ this.props.cur } />
            <p className="about__name">島崎 隆次 (シマザキ タカツグ)</p>
            <p className="about__detail">神奈川県生まれ。文化服装学院卒。<br /><br className="forPc" />ディレクターを経て、グラフィック撮影・動画撮影を担当。<br /><br />生活に密着したような人肌感のある雰囲気のあるビジュアル制作を得意としています。主にドキュメンタリー・ポートレイト・フードの撮影を行なっています。<br />Awwards,CSSDesignAwards他各賞を受賞。<br /><br />2021.06.28<br />Takatsugu Shimazaki Photo Exhibition 「spare intervals」展示開催</p>
            {/* <p className="about__online">TAKATSUGU SHIMAZAKI ONLINE STORE</p> */}
            <a href="https://www.takatsugushimazaki-store.com" className="about__online" target='_blank'>TAKATSUGU SHIMAZAKI ONLINE STORE</a>
            {/* <ul className="about__link">
              <li><a href="https://www.sonicjam.co.jp/" target="_blank">sonicjam</a></li>
              <li><a href="http://sonicjam.studio/" target="_blank">sonicjam.studio</a></li>
            </ul> */}
          </div>
          <div className="__hide">
            <ul className="__hide">{ SHIMA_PORTFOLIO.Works.imagesDom[SHIMA_PORTFOLIO.Works.activeCategoryIdx] }</ul>
          </div>
        </div>
      </div>
    )
  }
}
